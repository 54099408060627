export const i18nDynamicStrings = {
  spoilerCreate: {
    searchMoviePlaceholder: 'Cherchez un film...',
    searchTVShowPlaceholder: 'Cherchez une série...',
    searchEpisodePlaceholder: 'Sélectionnez un épisode...',
    searchDefaultPlaceholder: 'Chercher...',
    spoilerTextareaPlaceholder: 'Ecris ton spoil ici...'
  },
  flashMessages: {
    spoilerCreated: 'Le spoil a bien été créé !',
    errorSpoilerCreation: `Une erreur s'est produite`
  },
  breadcrumbs: {},
  header: {
    addSpoilerFor: 'Ajoute un spoil pour',
    addSpoiler: 'Ajoute un spoil'
  },
  misc: {
    season: 'Saison',
    movies: 'Films',
    tvShows: 'Séries'
  }
}
