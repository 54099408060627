import { WorkType } from '../app/common/enums/work-type.enum'
import { Episode } from '../app/common/interfaces/episode.interface'
import { MetaObject } from '../app/common/interfaces/meta-object.interface'
import { Movie } from '../app/common/interfaces/movie.interface'
import { Season } from '../app/common/interfaces/season.interface'
import { TvShow } from '../app/common/interfaces/tv-show.interface'

export const i18nMeta = {
  getMovieDetailMeta: (m: Movie, idAndSlug: string): MetaObject => ({
    path: `/movies/${idAndSlug}`,
    title: `Spoils du film ${m.name}`,
    description: `Tous les Spoils en Français du film "${m.name}". La fin du film révélée en 100 caractères (SPOILER)`
  }),
  getTvShowDetailMeta: (t: TvShow, idAndSlug: string): MetaObject => ({
    path: `/tv-shows/${idAndSlug}`,
    title: `Spoils de la série ${t.name}`,
    description: `Tous les Spoils en Français de la série "${t.name}" (${t.seasons.length} saisons). Spoils épisode par épisode sur la plus grande base de données de spoils au monde : Spoil Me.`
  }),
  getSeasonDetailMeta: (s: Season, tvShowIdAndSlug: string): MetaObject => ({
    path: `/tv-shows/${tvShowIdAndSlug}/seasons/${s.number}`,
    title: `Spoils de la Saison ${s.number} de la série ${s.tvShow.name}`,
    description: `Tous les Spoils en Français la saison ${s.number} de la série "${s.tvShow.name}". Spoils épisode par épisode sur la plus grande base de données de spoils au monde : Spoil Me.`
  }),
  getEpisodeDetailMeta: (
    e: Episode,
    tvShowIdAndSlug: string,
    episodeNumberAndSlug: string
  ): MetaObject => ({
    path: `/tv-shows/${tvShowIdAndSlug}/seasons/${e.season.number}/episodes/${episodeNumberAndSlug}`,
    title: `Spoils de ${e.season.tvShow.name} ${e.name}`,
    description: `Tous les Spoils en Français de l'épisode ${e.name} de la saison ${e.season.number} de la série "${e.season.tvShow.name}". Spoils épisode par épisode sur Spoil Me.`
  }),
  getWorkIndexMeta: (workType: WorkType, firstLetter: string): MetaObject => {
    if (workType === WorkType.Movie) {
      return {
        path: `/movies/index/${firstLetter}`,
        title: `Films qui commencent par ${firstLetter} spoilés sur Spoilme`,
        description: `Films qui commencent par ${firstLetter} spoilés sur Spoilme`
      }
    }
    if (workType === WorkType.TvShow) {
      return {
        path: `/tv-shows/index/${firstLetter}`,
        title: `Séries qui commencent par ${firstLetter} spoilées sur Spoilme`,
        description: `Séries qui commencent par ${firstLetter} spoilées sur Spoilme`
      }
    }
  },
  static: {
    homeMovies: {
      title: `La 1ère Base Mondiale de Spoils de Films`,
      description:
        'Découvre et contribue à la plus grande base de données de spoils de films au Monde. Plus de 10 000 films déjà spoilés !',
      path: ''
    },
    homeTvShows: {
      title: `La 1ère Base Mondiale de Spoils de Séries`,
      description: `Découvre et contribue à la plus grande base de données de spoils de séries au Monde. Plus de 10 000 film et séries déjà spoilés !`,
      path: 'tv-shows'
    },
    spoilerCreate: {
      title: 'Ajoute ton spoil',
      description:
        'Contribue à la plus grande base mondiale de spoils en spoilant les films que tu as vu !',
      path: '/movies/spoilers/create'
    },
    privacyLegacy: {
      title: `Privacy Legacy`,
      path: 'privacy-legacy'
    },
    error404: {
      title: `Error 404 : Page not found`,
      path: '404'
    }
  }
}
